.footerCompleteCon {
  background: rgba(14, 22, 51, 1);
  display: flex;
  flex-direction: column;
  z-index: 2;
}
.footerUpCon {
  padding: 50px 180px;
}

.footerUp1_1 {
  width: 281px;
}
.footerUp2 {
  color: #fff;
  font-family: 'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-right: 20px;
}
ul {
  list-style: none;
}
h5 {
  padding-bottom: 10px;
}
.h5_footer_heading {
  font-family: 'Montserrat';
  font-size: 18px;
  font-weight: 600;
  line-height: 21.94px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
}

.footer_up_2 {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
  gap: 70px;
}
.footer_up_right {
  display: flex;
  justify-content: right;
  gap: 70px;
}
.footerUp3 {
  letter-spacing: 1px;
  color: #fff;
  font-family: 'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-right: 20px;
}
.footerUp1_1_text {
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: rgba(178, 181, 194, 1);
}
.footerLinksLi {
  letter-spacing: 0.7px;
  color: rgba(178, 181, 194, 1);
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-transform: none;
  padding: 5px 0;
  text-decoration: none;
}
.footerLinksLi:hover {
  color: white;
}
.footerUp4 {
  color: #fff;
  font-family: 'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.footerInput {
  color: rgba(0, 0, 0, 0.4);
  font-family: 'Poppins';
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 15px;
  background: #fff;
  padding: 5px 5px 5px 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.7));
  margin-top: 10px;
  border: none;
}
.inputIconWrapper {
  position: relative;
  display: inline-block;
}

.footerInput {
  padding-left: 50px;
}

.inputIcon {
  position: absolute;
  left: 20px;
  top: 60%;
  transform: translateY(-50%);
  height: 15px;
}

.footerSubBtn {
  border: none;
  border-radius: 15px;
  background: #fff;
  color: #000;
  font-family: 'Poppins';
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 5px 20px;
  gap: 10px;
  margin-top: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.7));
}
.footerSubBtn:hover {
  color: #fff;
  background-color: #2f353f;
  cursor: pointer;
}
.footerDownCon {
  padding: 15px 0;
  border-top: 2px solid rgba(47, 53, 77, 1);
}
.footerDown1 {
  display: flex;
  gap: 10px;
  justify-content: left;
}

.footerDown1_icons {
  width: 44px;
  height: 44px;
  gap: 0px;
  opacity: 1;
  background: rgba(34, 40, 64, 1);
  border: 1px solid rgba(47, 53, 77, 1);
  border-radius: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 0.3s ease;
}

.footerDown1_icons:hover {
  border: 1px solid rgba(105, 138, 255, 1);
}

.footerDown1_icons .svg-path {
  stroke: white;
  transition: stroke 0.3s ease;
}

.footerDown1_icons:hover .svg-path {
  stroke: rgba(105, 138, 255, 1);
}
.footerDown2 {
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: center;
  color: rgba(178, 181, 194, 1);
}
@media (min-width: 534px) and (max-width: 1024px) {
  .footerCompleteCon {
    padding: 0;
  }
  .footerUpCon {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 20px 40px 20px;
    padding-bottom: 40px;
    border-bottom: #fff 2px solid;
  }
  .footerDownCon {
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 534px) {
  .footerCompleteCon {
    padding: 0;
  }
  .footerUpCon {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 20px 40px 20px;
    padding-bottom: 40px;
    border-bottom: #fff 2px solid;
  }
  .footerDownCon {
    padding: 15px 20px;
  }
  .footer_up_2 {
    margin-top: 30px;
    flex-direction: column;
  }
  .footer_up_right {
    gap: 20px;
    flex-direction: column;
    margin-top: 30px;
  }
}
