.Container {
    max-width: 300px;
    width: 300px;
    height: auto;
    cursor: pointer;
    z-index: 1;
    position: relative;
    border-radius: 8px 8px 8px 8px;
}


  .video_content_container {
    position: relative;

    height: 118%;
    padding: 1rem;
    padding-bottom: 20px;
  }