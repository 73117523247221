.main-container-nav {
  border-bottom: 1px solid #eff0f1;
  position: sticky;
  top: 0px;
  z-index: 10;
  background-color: white;
  font-family: 'Montserrat', sans-serif;
}

.nav-container {
  max-width: 1440px;
  margin: 0px 150px;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-wraper {
  width: fit-content;
}

.logo-wraper img {
  object-fit: cover;
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Nav-menu-wraper {
  gap: 16px;
  display: flex;
  align-items: center;
}

.Nav-menu-wraper a {
  text-decoration: none;
  font-weight: 500;
  font-size: 15px !important;
  color: rgba(2, 17, 72, 0.9);
}

.Nav-menu-wraper a.active {
  color: #698aff;
  font-weight: 600;
}

.Nav-menu-wraper a:hover {
  color: #5777ea;
  font-weight: 600;
}

.secondary-btn {
  padding: 12px 32px;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(242, 242, 242, 0.5);
  border-radius: 40px;
  border: 2px #f2f2f2 solid;
  font-size: 16px !important;
  font-weight: 500;
  line-height: 1.3;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.secondary-btn:hover {
  border: 2px solid rgba(167, 186, 252, 0.2);
  box-shadow: 0px 0px 15px 0px rgba(105, 138, 255, 0.25);
  background: rgba(105, 138, 255, 1);
  color: white;
}

.buttons-wraper .secondary-btn {
  width: 100%;
}

.hamburger {
  display: none;
  cursor: pointer;
  font-size: 24px;
}

.is-responsive {
  display: none;
}

.buttons-wraper {
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding-bottom: 0px;
  align-items: center;
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .nav-container {
    margin: 0 40px;
  }
}

@media (max-width: 1024px) {
  .nav-container {
    margin: 0 20px;
  }
}

@media (max-width: 900px) {
  .is-desktop-view {
    display: none;
  }

  .is-responsive {
    display: flex;
  }

  .Nav-menu-wraper a {
    width: 100%;
    padding: 6px 0;
    border-bottom: 1px solid rgba(2, 17, 72, 0.1);
    line-height: 36px;
  }

  .buttons-wraper {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding-bottom: 16px;
  }

  .buttons-wraper:first-child {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding-bottom: 0px;
  }

  .Nav-menu-wraper a:focus-visible {
    background-color: rgba(0, 0, 0, 0.12);
  }

  .Nav-menu-wraper {
    display: none;
    flex-direction: column;
    gap: 0px;
    position: absolute;
    z-index: 10;
    top: 60px;
    right: 0;
    background: white;
    width: 40%;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 8px;
  }

  .main-container {
    border-bottom: 1px solid #eff0f1;
  }

  .Nav-menu-wraper.open {
    display: flex;
  }

  .hamburger {
    display: block;
  }

  .secondary-btn {
    margin-top: 16px;
  }

  .logo-wraper img {
    width: 130px;
  }
}

@media (max-width: 768px) {
  .nav-container {
    margin: 0 20px;
  }
}

@media (max-width: 600px) {
  .secondary-btn {
    padding: 8px 24px;
    font-size: 14px !important;
  }
}

@media (max-width: 414px) {
  .nav-container {
    margin: 0 20px;
  }

  .secondary-btn {
    padding: 6px 20px;
    font-size: 12px !important;
  }
}

@media (max-width: 320px) {
  .nav-container {
    margin: 0 10px;
  }

  .secondary-btn {
    padding: 4px 16px;
    font-size: 10px !important;
  }
}
