@media only screen and (min-width: 1920px) {
  .OrganizationInvitesCon {
    height: 100vh;
  }
}
.OrganizationInvitesCon {
  display: flex;
  gap: 20px;
  justify-content: space-around;
  padding-top: 15px;
  margin-left: 200px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
}
.OrganizationInvitesCon-Settings {
  display: flex;
  gap: 20px;
  justify-content: space-around;
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
}

.SentReceivedInvites-1 {
  border-radius: 30px;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
  width: 100%;
  height: 46.5%;
  margin-bottom: 20px;
}

.SentReceivedInvites-1-MemberList {
  height: 289px;
  min-width: 450px;
}
.SendInvitesCon-Phone {
  display: none;
}
.SendInvitesCon {
  display: flex;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
  padding: 20px;
  gap: 30px;
  margin-top: 20px;
}
.SentReceivedInvites-1 > p {
  color: #000;
  font-family: 'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.SendInvites-leftCon {
  color: #000;
  font-family: 'Poppins';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.35);
  padding: 20px;
}
.SendInvites-leftCon > textarea {
  margin-top: 10px;
  color: #333;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 367.624px;
  height: 180px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 2px 4px 4px 2px rgba(0, 0, 0, 0.25);
  padding: 10px;
  border: none;
}
.SendInvites-BtnCon {
  display: flex;

  justify-content: right;
  margin-top: 20px;
}
.SendInvites-Btn-AddEmail {
  border-radius: 15px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  color: rgba(47, 53, 63, 0.4);
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  gap: 8px;
  cursor: pointer;
}
.SendInvites-Btn-AddEmail:hover {
  background-color: rgba(194, 195, 199, 0.4);
}
.SendInvites-Btn-SendInvites {
  color: #fff;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 15px;
  background: #93a9fa;
  border: none;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  gap: 8px;
  cursor: pointer;
}
.SendInvites-Btn-SendInvites:hover {
  background-color: #7587c8;
}
.SendInvites-AddEmailCon {
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.35);
  padding: 20px;
  border-radius: 30px;
}
.SendInvitesCon-addingEmailsCon {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}
.SendInvitesCon-addingEmailsCon > p {
  color: #000;
  font-family: 'Poppins';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.SendInvites-leftCon-Main > p {
  color: #000;
  font-family: 'Poppins';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}
.SendInvites-AddEmailCon > p {
  color: #000;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.SendInvites-AddEmail-EmailList {
  height: 230px;
  overflow: scroll;
}

.memberTitle {
  text-overflow: ellipsis;
  width: 17ch;
  overflow: hidden;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(2, 17, 72, 0.9);
}

.OrganizationInvites-SearchUsers > p {
  color: #000;
  font-family: 'Poppins';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}

.custom-scrollbar {
  max-height: 289px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #354150 #e4efef;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #e4efef;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #354150;
  border-radius: 2px;
}
.DataFromApiSent {
  color: #797979;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  margin-top: 20px;
}
.DataFromApiReceived {
  color: #797979;
  font-family: 'Poppins';
  font-size: 15px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  align-items: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.OrganizationInvites-SearchUsers-List > p {
  color: #797979;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  margin-top: 20px;
  width: 300px;
}
.MembersList-ButtonsCont {
  display: flex;
  gap: 10px;
  align-items: center;
}
.MembersList-Role {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 5px 10px;
  color: rgba(0, 0, 0, 0.5);
  font-family: 'Poppins';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.MembersList-RemoveIcon {
  border-radius: 10px;
  background: #fff;
  padding: 5px 8px;
  cursor: pointer;
}
.MembersList-Select {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: rgba(7, 28, 62, 1);
  border: none;
  cursor: pointer;
}
.MembersList-Select > option {
  padding: 10px;
  border: none;
  border-bottom: 1px solid rgba(2, 17, 72, 0.1);
}
@media only screen and (max-width: 1420px) and (min-width: 1090px) {
  .OrganizationInvitesCon {
    flex-direction: column;
  }
  .OrganizationInvitesCon-Settings {
    flex-direction: column;
  }
  .SendInvitesCon {
    flex-direction: column;
  }
  .SendInvites-leftCon > textarea {
    margin-top: 10px;
    font-size: 16px;
    width: 100%;
  }
  .OrganizationInvites-SearchUsers {
    width: 100%;
  }
}
@media only screen and (max-width: 1420px) and (min-width: 600px) {
  .OrganizationInvitesCon {
    width: auto;
  }
  .OrganizationInvitesCon-Settings {
    width: auto;
  }
  .OrganizationInvites-SearchUsers {
    width: 100%;
  }

  .SentReceivedInvites-1 {
    width: 100%;
  }
}

@media only screen and (max-width: 1090px) and (min-width: 600px) {
  .OrganizationInvitesCon {
    flex-direction: column;
  }
  .OrganizationInvitesCon-Settings {
    flex-direction: column;
  }

  .SendInvitesCon {
    flex-direction: column;
  }
  .SendInvites-leftCon > textarea {
    margin-top: 10px;
    font-size: 16px;
    width: 100%;
  }
  .SendInvitesCon {
    display: none;
  }
  .SendInvitesCon-Phone {
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
    padding: 20px;
    gap: 30px;
    margin-top: 20px;
  }
  .SendInvites-BtnCon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-items: center;
  }
  .SendInvites-Btn-AddEmail {
    align-items: center;
    width: 175px;
  }
  .SendInvites-Btn-SendInvites {
    align-items: center;
    padding-left: 20px;
    width: 175px;
  }
}

@media only screen and (max-width: 600px) {
  .OrganizationInvitesCon {
    flex-direction: column;
    margin: 15px;
    width: auto;
    padding: 20px 15px 30px 15px;
  }
  .OrganizationInvitesCon-Settings {
    flex-direction: column;
    margin: 0px;
    width: auto;
    padding: 0px;
  }

  .SendInvitesCon {
    display: none;
  }
  .SendInvitesCon-Phone {
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
    padding: 20px;
    gap: 30px;
    margin-top: 20px;
  }
  .SendInvites-BtnCon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-items: center;
  }
  .SendInvites-Btn-AddEmail {
    align-items: center;
    width: 175px;
  }
  .SendInvites-Btn-SendInvites {
    align-items: center;
    padding-left: 20px;
    width: 175px;
  }
  .SendInvites-leftCon > textarea {
    margin-top: 10px;
    font-size: 16px;
    width: 100%;
  }

  .InviteWithCodeDiv > p {
    font-size: 20px;
  }
  .OrganizationInvites-SearchUsers {
    width: 100%;
  }
  .OrganizationInvites-SearchUsers > p {
    font-size: 20px;
  }
  .InviteWithCodeDetails-Code {
    font-size: 18px;
  }
  .SentReceivedInvites-1 {
    width: 100%;
  }
  .SentReceivedInvites-1 > p {
    font-size: 16px;
  }

  .DataFromApiReceived {
    font-size: 16px;
  }
  .DataFromApiSent {
    font-size: 16px;
  }
  .InviteWithCodeDiv > p {
    font-size: 16px;
  }
  .SendInvites-leftCon {
    font-size: 19.5px;
  }
  .SentReceivedInvites-1-MemberList {
    max-width: 310px;
    min-width: 0;
  }

  .pageContainerTitle {
    margin-left: 30px;
    margin-top: 30px;
  }
}
