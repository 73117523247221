.inviteActionModal {
    border-radius: 8px;
    background: #FFF;
    position: absolute;
    width: 50%;
    padding: 8px;
    left: 50%;
    top: 50%;
    padding: 20px;
    transform: translate(-50%, -50%);
    outline: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.inviteActionClose {
    background-color: white;
    border-radius: 30px;
    border-style: none;
    cursor: pointer;

}

.inviteActionHead {
    color: #000;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.inviteActionSubhead {
    color: #000;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.inviteActionCon {
    display: flex;
    justify-content: flex-start;
    padding: 14px;
    border-radius: 15px;
    background: #FFF;
}

.inviteActionOrg {
    display: flex;
    justify-content: space-evenly;
    gap: 12px;
}


.inviteActionOrgName>h3 {
    color: #000;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.inviteActionBtns {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
}

.btn-accept {
    border-radius: 15px;
    background: #61CB8E;
    width: 150px;
    border-style: none;
    margin: 8.5px;
    cursor: pointer;
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 50px;
    flex-shrink: 0;
}

.btn-viewOrg {
    border-radius: 15px;
    background: #98AFFF;
    width: 150px;
    border-style: none;
    margin: 8.5px;
    cursor: pointer;
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 45px;
    flex-shrink: 0;
}

.btn-deny {
    border-radius: 15px;
    background: #FF3E3E;
    width: 150px;
    border-style: none;
    margin: 8.5px;
    cursor: pointer;
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 50px;
    flex-shrink: 0;
}

.btn-accept,
.btn-deny,
.btn-viewOrg {
    position: relative;
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.btn-accept:hover,
.btn-deny:hover,
.btn-viewOrg:hover {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 800px) {
    .inviteActionModal {
        width: 90%;
    }

    .inviteActionHead {
        font-size: 25px;
    }

    .inviteActionSubhead {
        font-size: 18px;
    }

    .inviteActionOrgName>h3 {
        font-size: 25px;
    }


    .btn-accept,
    .btn-deny,
    .btn-viewOrg {
        margin: 0px;
        padding: 2px;
        width: 120px;
    }
}