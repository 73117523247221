.careers-container {
  max-width: 1440px;
  padding: 80px 150px;
  display: flex;
  align-items: center;
  gap: 100px;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
}
.mailHereLink {
  color: #698aff;
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.careers-left-side {
  flex: 1.4;
}

.careers-right-side {
  flex: 1;
}

.careers-heading-hero {
  font-size: 42px;
  font-weight: 600;
  line-height: 1.3;
}

.sub-description {
  font-size: 18px;
  line-height: 1.6;
  color: rgba(62, 67, 75, 0.9);
  font-weight: 500;
  letter-spacing: 0.25;
}

.icon-text-btn {
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-weight: 600;
  color: #021148 !important;
  line-height: 1.3;
  font-family: 'Montserrat', sans-serif;
  display: inline-flex;
  width: fit-content;
  border-bottom: 1px solid transparent;
  padding-bottom: 8px;
  cursor: pointer;
  margin-top: 24px;
}

.careers-img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.careers-benefit-container {
  max-width: 1440px;
  padding: 80px 150px;
  display: flex;
  align-items: baseline;
  gap: 40px;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
}

/* .careers-heading-1 {
  font-size: 38px;
  line-height: 1.3;
  font-weight: 600;
  letter-spacing: -0.5px;
} */

.career-benefit-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  row-gap: 20px;
  flex-wrap: wrap;
}

.career-benefit-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  width: calc(50% - 20px);
  height: fit-content;
}

.career-benefits-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;
}

.career-benefit-title {
  color: #021148;
  font-size: 22px;
  line-height: 1.2;
  font-weight: 600;
}

.career-benefit-description {
  color: rgba(100, 100, 100, 1);
  font-size: 16px;
  line-height: 1.6;
}

.careers-jobs-container {
  max-width: 1440px;
  margin: 0 150px;
  padding: 80px 0px;
  font-family: 'Montserrat', sans-serif;
}

.careers-heading {
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 20px;
}

.no-openings-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.no-opening-content {
  width: 100%;
  border-radius: 12px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-opening-img {
  width: 100%;
  max-width: 270px;
  height: auto;
  object-fit: cover;
  margin-top: 50px;
}

.no-opening-text {
  text-align: center;
}
.cvEmailText {
  display: flex;
  align-items: center;
}
.sub-heading {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 16px;
}

.bg-secondary-color {
  background-color: rgba(246, 248, 255, 1);
}

.primary {
  color: #698aff !important;
}

.secondary-text-color {
  color: #021148;
}

.ml-8 {
  margin-left: 8px;
}

.mt-12 {
  margin-top: 12px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mt-16 {
  margin-top: 16px;
}

/* Responsive Adjustments */

/* Laptop View */
@media screen and (max-width: 1200px) and (min-width: 900px) {
  .careers-container,
  .careers-benefit-container,
  .careers-jobs-container {
    max-width: 100%;
    padding: 60px 40px;
    margin: 0 40px;
    gap: 60px;
  }

  .career-benefit-item {
    width: calc(50% - 20px);
    gap: 12px;
  }

  .icon-text-btn {
    font-size: 16px;
  }
}

/* Tablet View */
@media screen and (max-width: 900px) {
  .careers-container,
  .careers-benefit-container,
  .careers-jobs-container {
    max-width: 100%;
    padding: 60px 40px;
    margin: 0 20px;
    gap: 40px;
    flex-direction: column;
  }

  .careers-left-side,
  .careers-right-side {
    width: 100%;
  }

  .careers-heading-hero {
    font-size: 36px;
  }

  .careers-heading {
    font-size: 32px;
  }

  .careers-heading,
  .careers-heading-hero {
    margin-bottom: 0px;
  }

  .career-benefits-content {
    gap: 4px;
  }

  .career-benefit-icon {
    width: 44px;
    height: 44px;
  }

  .career-benefit-title {
    font-size: 20px;
  }

  .icon-text-btn {
    font-size: 16px;
  }

  .career-benefit-item {
    width: 100%;
    gap: 12px;
  }
}

/* Mobile View */
@media screen and (max-width: 600px) {
  .careers-container,
  .careers-benefit-container,
  .careers-jobs-container {
    max-width: 100%;
    padding: 40px 20px;
    margin: 0 0px;
    gap: 40px;
  }

  .careers-heading-hero {
    font-size: 32px;
  }

  .sub-description {
    font-size: 16px;
  }

  .careers-heading {
    font-size: 28px;
  }

  .career-benefit-title {
    font-size: 18px;
  }

  .no-opening-img {
    max-width: 200px;
  }
}

/* Small Mobile View */
@media screen and (max-width: 480px) {
  .careers-container,
  .careers-benefit-container,
  .careers-jobs-container {
    max-width: 100%;
    padding: 40px 20px;
    margin: 0 0px;
  }

  .careers-container {
    gap: 40px;
  }

  .careers-benefit-container {
    gap: 20px;
  }

  .careers-heading {
    line-height: 1.4;
  }

  .career-benefit-title {
    font-size: 16px;
  }

  .career-benefit-description {
    font-size: 14px;
  }

  .career-benefit-icon {
    width: 40px;
    height: 40px;
  }

  .icon-text-btn {
    font-size: 14px;
  }

  .icon-text-btn .icon {
    height: 20px;
    width: 20px;
  }

  .career-benefit-item {
    width: 100%;
    gap: 12px;
  }

  .no-openings-container {
    margin-top: 20px;
  }

  .sub-heading {
    font-size: 16px;
  }

  .no-opening-text p:nth-child(2) {
    font-size: 14px;
  }

  .no-opening-content {
    padding: 30px;
  }

  .no-opening-img {
    max-width: 180px;
  }
}
