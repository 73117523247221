.planSection {
  padding: 20px;
  margin-left: 200px;
}

.planHead {
  font-family: 'Montserrat';
  font-size: 40px;
  font-weight: 700;
  line-height: 51px;
  text-align: center;
  color: rgba(2, 17, 72, 1);
}

.planSubhead {
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: 400;
  line-height: 24.38px;
  text-align: center;
  color: rgba(25, 29, 35, 1);
  margin-top: 10px;
  margin-bottom: 20px;
}

.planDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.planCardCurr {
  margin: 6px;
  width: 364px;
  padding: 22px 24px 32px 24px;
  border-radius: 12px;
  box-shadow: 0px 10px 25px 0px rgba(255, 234, 219, 1);
  background: rgba(105, 138, 255, 1);
}
.AvailableplanCard {
  margin: 6px;
  width: 364px;
  padding: 32px 24px 32px 24px;
  border-radius: 12px;
  background: rgba(246, 248, 255, 1);
}
.planValidUpto {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: rgba(247, 248, 249, 1);
  margin-top: 5px;
}

@media screen and (max-width: 600px) {
  .planSection {
    margin-left: 0;
  }
}

.planCard:hover {
  transform: translateY(-8px);
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
}

.activeNote {
  padding: 5px 10px;
  display: inline-block;
  width: max-content;
  border-radius: 15px;
  background-color: rgba(255, 253, 253, 0.5);
  margin: 6px;
  color: white;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.planCardHead {
  font-family: 'Montserrat';
  font-size: 22px;
  font-weight: 600;
  line-height: 26.82px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}
.AvailableplanCardHead {
  font-family: 'Montserrat';
  font-size: 22px;
  font-weight: 600;
  line-height: 26.82px;
  text-align: left;
  color: rgba(70, 70, 70, 1);
}
.planCardPrice {
  color: white;
  font-family: 'Montserrat';
  font-size: 48px;
  font-weight: 600;
  line-height: 58.51px;
  text-align: left;
  margin-top: 10px;
}
.AvailableplanCardPrice {
  color: rgba(70, 70, 70, 1);
  font-family: 'Montserrat';
  font-size: 48px;
  font-weight: 600;
  line-height: 58.51px;
  text-align: left;
  margin-top: 20px;
}

.planCardSubhead {
  color: white;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
}
.AvailableplanCardSubhead {
  color: rgba(70, 70, 70, 1);
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  margin-top: 20px;
}
.planCard > ul {
  overflow: auto;
  display: block;
  height: 330px;
}

.planCardList {
  color: white;
  display: flex;
  justify-content: flex-start;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  align-items: center;
  gap: 17px;
  margin-bottom: 16px;
}
.AvailableplanCardList {
  color: rgba(70, 70, 70, 1);
  display: flex;
  justify-content: flex-start;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  align-items: center;
  gap: 17px;
  margin-bottom: 16px;
}

.planCardPer {
  color: white;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 300;
  line-height: 19.5px;
  text-align: left;
  margin-left: 5px;
}
.AvailableplanCardPer {
  color: rgba(70, 70, 70, 1);
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 300;
  line-height: 19.5px;
  text-align: left;
  margin-left: 5px;
}
.planCard > button:disabled {
  background: #e7e7e7;
  cursor: not-allowed;
}

.planCard > button:disabled:hover {
  background: #e7e7e7;
  cursor: not-allowed;
}

.planCard > button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #698aff;
  color: #fff;
  font-family: 'Poppins';
  font-size: 24px;
  position: absolute;
  bottom: 12px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.45));
  cursor: pointer;
  left: 0;
  right: 0;
  margin: auto;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 210px;
  height: 60px;
  flex-shrink: 0;
  border-style: none;
  margin-bottom: 15px;
}

.planCard > button > img {
  width: 20px;
  height: auto;
  margin-left: 20px;
}

.planCard > button:hover {
  background-color: rgb(73, 96, 178);
}
.AvailableGetStartedBtn {
  padding: 8px 12px;
  border: 1px solid rgba(105, 138, 255, 1);
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: center;
  color: rgba(105, 138, 255, 1);
  background-color: white;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 8px;
}
.AvailableGetStartedBtnDisabled {
  padding: 8px 12px;
  border: 1px solid #adadad;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: center;
  color: #adadad;
  background-color: white;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
}
