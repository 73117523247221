.viewSecondRow-orgDetailsMemberBlock {
  display: flex;
  justify-content: space-between;
  background: #fff;
  gap: 40px;
  padding: 16px 10px;
  align-items: center;
  border-bottom: 1px solid rgba(225, 225, 225, 1);
}
.viewSecondRow-Notification {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 5px 10px;
  align-items: center;
}
.viewSecondRow-orgDetailsMemberBlockDets {
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 1; 
}
.viewSecondRow-orgDetailsMemberBlockDetsCont {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}
.viewSecondRow-orgDetailsMemberBlock-Email {
  border-radius: 6px;
  background: #fff;
  padding: 16px 10px;
  align-items: center;
  border-bottom: 1px solid rgba(225, 225, 225, 1);
}
.viewSecondRow-orgDetailsMemberBlockDetsCont-Email {
  display: flex;
  gap: 15px;
}
.viewSecondRow-orgDetailsMemberBlock-Email > img {
  width: 40px;
  height: 40px;
}
.viewSecondRow-orgDetailsMemberBlock > img {
  width: 40px;
  height: 40px;
}
.viewSecondRow-orgDetailsMemberBlock-Invite {
  display: flex;
  justify-content: space-between;
  padding: 16px 10px;
  border-bottom: 1px solid rgba(230, 230, 230, 1);
}
.viewSecondRow-orgDetailsMemberBlockDets-Email {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 100%;
}
.member-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(2, 17, 72, 0.9);
}
.initials-container {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
}

@media (max-width: 600px) {
  .initials-container {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
  }
}

.pending-invite-name-email {
  display: flex;
  gap: 15px;
  align-items: center;
}
.pending-status {
  padding: 2px 8px;
  gap: 4px;
  border-radius: 3px 0px 0px 0px;
  opacity: 0px;
  background: rgba(243, 246, 255, 1);
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: rgba(94, 98, 110, 1);
  border: none;
}
.Pending-cancel-invite-btn {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: rgba(7, 28, 62, 1);
  background-color: white;
  border: none;
  cursor: pointer;
  margin-right: 5px;
}
.pending-invite-status-cancelbtn {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  width: 100%;
}
.pending-invite-name-email > p {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(62, 67, 75, 0.5);
}
.viewSecondRow-orgDetailsMemberBlockDets > span {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(62, 67, 75, 0.9);
}

.viewSecondRow-orgDetailsMembersListNotification {
  max-height: 300px;
  overflow: scroll;
}
.MembersList-AcceptedBtn {
  border-radius: 12px;
  background: rgba(41, 183, 102, 0.2);
  color: #29b766;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border: none;
  padding: 3px 0px;
  width: 110px;
  cursor: pointer;
}
.ViewBtnNotification {
  font-size: 16px;
  cursor: pointer;
}
.MembersList-RejectedBtn {
  border-radius: 12px;
  background: rgba(255, 62, 62, 0.2);
  color: #ff3e3e;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border: none;
  padding: 3px 0px;
  width: 110px;
  cursor: pointer;
}
.MembersList-inviteReceived {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 0px;
}
.MembersList-inviteReceived-blue {
  display: flex;
  gap: 10px;
}
.MembersList-inviteReceived-Accept {
  border-radius: 12px;
  background: rgba(97, 203, 142, 1);
  color: white;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border: none;
  padding: 4px 0px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
  cursor: pointer;
}
.MembersList-inviteReceived-Reject {
  border-radius: 12px;
  background: rgba(255, 62, 62, 1);
  color: white;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border: none;
  padding: 4px 0px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
  cursor: pointer;
}
.viewSecondRow-orgDetailsMemberBlock-PhotoUserName {
  display: flex;
  gap: 10px;
  align-items: center;
}
.MembersList-inviteReceived-Accept-blue {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: rgba(7, 28, 62, 1);
  background-color: white;
  border: none;
  cursor: pointer;
}

@media only screen and (max-width: 1300px) {
  .viewSecondRow-orgDetailsMemberBlockDetsCont {
    flex-direction: row;
  }
}
@media only screen and (max-width: 1064px) {
  .viewSecondRow-orgDetailsMemberBlock {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 16px 10px;
  }
  .viewSecondRow-Notification {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 5px;
    width: 100%;
  }

  .MembersList-AcceptedBtn {
    font-size: 12px;
    width: 80px;
  }
  .ViewBtnNotification {
    font-size: 12px;
    width: 97px;
  }
  .MembersList-inviteReceived-Accept {
    font-size: 12px;
    width: 80px;
  }
  .MembersList-RejectedBtn {
    font-size: 12px;
    width: 80px;
  }
  .MembersList-inviteReceived-Reject {
    font-size: 12px;
    width: 80px;
  }
  .pending-invite-name-email > p {
    font-size: 12px;
  }
}
@media only screen and (max-width: 600px) {
  .viewSecondRow-orgDetailsMemberBlockDetsCont {
    flex-direction: column;
  }
  .viewSecondRow-orgDetailsMemberBlock {
    padding: 16px 0px;
    width: 100%;
  }
  .MembersList-inviteReceived-blue {
    gap: 5px;
  }
  .viewSecondRow-orgDetailsMembersList {
    width: 100%;
  }

  .viewSecondRow-orgDetailsMemberBlockDets-Email {
    flex-direction: column;
    align-items: flex-start;
  }
  .viewSecondRow-orgDetailsMemberBlock-Invite {
    padding: 16px 0px;
    overflow: auto;
  }
  .viewSecondRow-orgDetailsMemberBlock-Email {
    padding: 16px 0px;
  }
  .member-name-container {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .viewSecondRow-orgDetailsMemberBlockDetsSpan {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
